body {
  margin: 0;
  font-family: 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');



.App {

  display: flexbox;
  flex-direction: column;
  align-items: center;
  
}

.loder{
 
  animation: loder 3s infinite;
}


@keyframes loder {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }

  
}



